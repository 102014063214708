import React from 'react';
import { Header } from 'components/theme';
import { Wrapper } from './styles';

export const Resume = () => (
	<Wrapper>
        <Header/>
            <h1>Benjamin Esh</h1>
            <h1>Hey there</h1>
            <p>This is my resume.</p>
	</Wrapper>
);
