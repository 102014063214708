import React from 'react'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'components/common'
import dev from 'assets/illustrations/skills.svg'
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="I’m Benjamin and I’m a full stack engineer" />
      </Thumbnail>
      <Details>
        <h1>Hi There!</h1>
        <p>
        I’m a full stack engineer living in Chicagoland area. I currently work at United Airlines, 
        but I had worked at DMD Marketing and for Robert Half International as a SPS consultant with 
        clients such as YMCA, P&K Research, Church Mutual, JLL and Protiviti. 
        </p>
        <a
            href="https://www.linkedin.com/in/benjamin-esh-7abb3b47/"
            target="_blank"
            rel="noopener noreferrer"
						aria-label={"See My Work History"}
          >
        <Button>
            See My Work History
        </Button>
        </a>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
