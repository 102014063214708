module.exports = {
  defaultTitle: 'Benjamin Esh',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Benjamin Esh',
  url: 'https://benjaminesh.com',
  legalName: 'Benjamin Esh',
  defaultDescription: 'I’m Benjamin and I’m a full stack engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/besh27',
    github: 'https://github.com/besh27',
    linkedin: 'https://www.linkedin.com/in/benjamin-esh-7abb3b47/',
    instagram: 'https://instagram.com/besh27',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@besh27',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
  recaptcha_key: '6Lda7dcUAAAAABM4a7bx-bnAKpT3c8Nef4_CYV34',
};
